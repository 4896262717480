<template>
  <div class="VideoBackground">
    <v-icon dark class="mute" @click.stop="toogleMute()"> {{ mute ? 'mdi-volume-off' : 'mdi-volume-high' }}</v-icon>
    <video playsinline autoplay loop :muted="mute" ref="video">
      <source v-for="(source, index) in sources" :key="index" :src="source" :type="getMediaType(source)">
    </video>
    <div class="VideoBg__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import './_VideoBackground.scss'

export default {
  name: 'VideoBackground',
  props: {
    sources: {
      type: Array,
      required: true
    },
    img: {
      type: String
    }
  },

  data() {
    return {
      mute: true,
      videoRatio: null
    }
  },

  mounted() {
    this.setImageUrl()
    this.setContainerHeight()

    if (this.videoCanPlay()) {
      this.$refs.video.oncanplay = () => {
        if (!this.$refs.video) return

        this.videoRatio = this.$refs.video.videoWidth / this.$refs.video.videoHeight
        this.setVideoSize()
        this.$refs.video.style.visibility = 'visible'
      }
    }

    window.addEventListener('resize', this.resize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },

  methods: {
    toogleMute() {
      this.mute = !this.mute
    },
    resize() {
      this.setContainerHeight()

      if (this.videoCanPlay()) {
        this.setVideoSize()
      }
    },

    videoCanPlay() {
      return !!this.$refs.video.canPlayType
    },

    setImageUrl() {
      if (this.img) {
        this.$el.style.backgroundImage = `url(${this.img})`
      }
    },

    setContainerHeight() {
      this.$el.style.height = `${window.innerHeight}px`
    },

    setVideoSize() {
      var width, height, containerRatio = this.$el.offsetWidth / this.$el.offsetHeight

      if (containerRatio > this.videoRatio) {
        width = this.$el.offsetWidth
      } else {
        height = this.$el.offsetHeight
      }

      this.$refs.video.style.width = width ? `${width}px` : 'auto'
      this.$refs.video.style.height = height ? `${height}px` : 'auto'
    },

    getMediaType(src) {
      const type = src.split('.').pop()
      if (type === 'm3u8') {
        return "application/x-mpegURL"
      }
      return 'video/' + type
    }
  }
};
</script>
