<template>
  <div class="Header">
    <v-app-bar
      color="transparent"
      dark
      height="128"
      class="elevation-0 toolbar"
    >
      <v-spacer></v-spacer>
      <v-img :src="require('@/assets/logo.png')" height="120" max-width="350" contain @click="$router.push({name: 'Home'})"></v-img>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
import './_Header.scss'

export default {
  name: 'Header'
};
</script>
