<template>
  <div class="Landing">
    <div style="height: calc(100vh); max-height: calc(100vh); position: fixed; top: 0;">
      <VideoBackground :sources="[require('@/assets/videos/home.mp4')]">
        <!-- If you want to add content here, a slot is waiting! -->
      </VideoBackground>
    </div>
    <v-container fluid class="content fill-height flex-nowrap">
      <v-row class="flex-nowrap">
        <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
        <v-col align-self="center" align="center">
          <div v-if="one && !form" class="box" align="center">
            <div class="boxTitle">Overview</div>
            <br><br>
            Department of Good is an innovative, for-profit, technology-driven platform for B2B and B2C retail.
            We create an interwoven ecosystem driven by blockchain, independent retailers, and global
            communities. We fundamentally believe that community empowerment and support for
            small businesses will help change our societies for the better. We manifest disruption
            for GOOD.
            <br><br>
            We built our business model based on questions and statistics which we felt were important
            to explore and create solutions for. We passionately believe our model can help empower
            communities - physical virtual, and like-minded.
            <br><br>
            The landscape of consumerism is constantly and rapidly changing - the shopping experience is
            become more and more essential. Over 70% of millennials prefer to shop in their local
            community stores.
            <br><br>
            We are excited at being able to partner and bring innovative and profitable products to the
            market, knowing that our purchases are not only unique, but that they also support your
            business goals and all your sales help our communities thrive.
            <br><br>
            <v-btn @click="one = false; two = true;" x-large rounded dark>Join the club</v-btn>
          </div>
          <div v-if="two && !form" class="box" align="center">
            <div class="boxTitle">Partner Types</div>
            <br><br>
            <v-container>
              <v-row>
                <v-col>
                  <v-avatar size="128" color="grey"><v-img :src="require('../../assets/retailstore.jpg')"></v-img></v-avatar>
                  <div class="title">Retail Store</div>
                  <div class="subtitle-1">You would like to carry our product offerings and become a Dept. of GOOD exclusive retailer.</div>
                </v-col>
                <v-col>
                  <v-avatar size="128" color="grey"><v-img :src="require('../../assets/artisan.jpg')"></v-img></v-avatar>
                  <div class="title">Artisan / Manufacturer</div>
                  <div class="subtitle-1">You have an idea or product needing to be helped to get to market.</div>
                </v-col>
                <v-col>
                  <v-avatar size="128" color="grey"><v-img :src="require('../../assets/charity.jpg')"></v-img></v-avatar>
                  <div class="title">Charity / Community Support</div>
                  <div class="subtitle-1">You’re a community organization - national or international.</div>
                </v-col>
                <v-col>
                  <v-avatar size="128" color="grey"><v-img :src="require('../../assets/wholesaler.jpg')"></v-img></v-avatar>
                  <div class="title">Brand / Wholesaler</div>
                  <div class="subtitle-1">You’re looking to join our mission - all sectors of consumer goods welcomed.</div>
                </v-col>
              </v-row>
            </v-container>
            <br>
            <v-btn @click="form = true" x-large rounded dark>Join the club</v-btn>
          </div>
          <div v-if="form" class="box" align="center">
            <div v-once>
              <script type="application/javascript">
                window.hbspt.forms.create({
                  portalId: "5179500",
                  formId: "a5c51553-78b3-40a3-af98-6136b794a4fc"
                });
              </script>
            </div>
          </div>
        </v-col>
        <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import VideoBackground from "@/components/VideoBackground/VideoBackground"
import './_Landing.scss'
export default {
  name: 'Landing',
  components: {VideoBackground},
  data () {
    return {
      one: true,
      two: false,
      form: false
    }
  }
};
</script>
